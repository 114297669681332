import { CommChannelCodePipe } from './communication/comm-channel.pipe';
import { CommDirectionCodePipe } from './communication/comm-direction.pipe';
import { CommonModule } from '@angular/common';
import { CountryCodePipe } from './form/country-code.pipe';
import { DateDiffPipe } from './date/date-diff.pipe';
import { DateIntervalPipe } from './date/date-interval.pipe';
import { ElapsedPipe } from './date/elapsed.pipe';
import { EmailPipe } from './form/email.pipe';
import { HeadPipe } from './array/head.pipe';
import { IpexTicketPipe } from './ipex/ipex-ticket.pipe';
import { GoogleMapsPipe } from './google-maps/google-maps.pipe';
import { MajorMinorDatePipe } from './date/major-minor-date.pipe';
import { MapSearchPipe } from './map-search.pipe';
import { NgModule } from '@angular/core';
import { OrElsePipe } from './or-else.pipe';
import { ParseDatePipe } from './date/parse-date.pipe';
import { PersonalInPipe } from './form/personal-in.pipe';
import { PhonePipe } from './form/phone.pipe';
import { PluralizePipe } from './localization/pluralize.pipe';
import { SafeHtmlPipe } from './sanitization/safe-html.pipe';
import { SafeUrlPipe } from './sanitization/safe-url.pipe';
import { ShortNumberPipe } from './number/short-number.pipe';
import { StrDatePipe } from './date/str-date.pipe';
import { StrDateTimePipe } from './date/str-date-time.pipe';
import { TaskCodeCodePipe } from './task/task-code.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { ZipCodePipe } from './form/zip-code.pipe';
import { CastPipe } from './cast.pipe';
import { StripHtmlPipe } from './sanitization/strip-html.pipe';

const pipes = [
  CountryCodePipe,
  OrElsePipe,
  ZipCodePipe,
  PhonePipe,
  TaskCodeCodePipe,
  PluralizePipe,
  SafeUrlPipe,
  SafeHtmlPipe,
  MapSearchPipe,
  CommDirectionCodePipe,
  CommChannelCodePipe,
  DateDiffPipe,
  DateIntervalPipe,
  MajorMinorDatePipe,
  ParseDatePipe,
  StrDatePipe,
  StrDateTimePipe,
  YesNoPipe,
  ShortNumberPipe,
  PersonalInPipe,
  IpexTicketPipe,
  GoogleMapsPipe,
  EmailPipe,
  HeadPipe,
  ElapsedPipe,
  CastPipe,
];
@NgModule({
  declarations: pipes,
  imports: [CommonModule, StripHtmlPipe],
  exports: [pipes, StripHtmlPipe],
})
export class PipesModule {}
