import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) {}

  /**
   * Intercept implementation that checks if the request url matches the excludedUrls.
   * If not, adds the Authorization header to the request if the user is logged in.
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { enableBearerInterceptor } = this.keycloak;
    const modified = req.clone({ setHeaders: { 'x-source': 'CORE_FRONTEND' } });
    if (!enableBearerInterceptor) {
      return next.handle(modified);
    }

    return from(this.conditionallyUpdateToken(modified)).pipe(
      mergeMap(() =>
        this.keycloak.isLoggedIn() ? this.handleRequestWithTokenHeader(modified, next) : next.handle(modified),
      ),
    );
  }

  /**
   * Calls to update the keycloak token if the request should update the token.
   *
   * @param req http request from @angular http module.
   * @returns
   * A promise boolean for the token update or noop result.
   */
  private async conditionallyUpdateToken(req: HttpRequest<unknown>): Promise<boolean> {
    // pokud nastane eventa expirace
    if (this.keycloak.shouldUpdateToken(req)) {
      return await this.keycloak.updateToken();
    }
    return true;
  }

  private handleRequestWithTokenHeader(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.keycloak.addTokenToHeader(req.headers).pipe(
      mergeMap((headersWithBearer) => {
        const kcReq = req.clone({ headers: headersWithBearer });
        return next.handle(kcReq);
      }),
    );
  }
}
