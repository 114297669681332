import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface HttpOpt {
  startSessionPath: string;
  startSessionTimeout: number;
}

export interface BinaryResponse {
  blob: Blob | null;
  fileName: string;
  fileType: string;
}

export interface ApiSession {
  kind: string;
  sessionId: string;
  error?: string; // TODO to bude pres extend universalni roz. pro chyby
}

export interface BinaryResponse {
  blob: Blob | null;
  fileName: string;
  fileType: string;
}

export interface ObserveResponseOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface BlobOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType: 'blob';
  withCredentials?: boolean;
}

export interface ArrayBufferOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType: 'arraybuffer';
  withCredentials?: boolean;
}

export interface TextOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType: 'text';
  withCredentials?: boolean;
}

export interface TestResponse extends ManyResult<TestObj> {}
export interface TestObj {
  a: string;
  b: number;
}

export interface ManyResult<T> {
  data: T[] | null;
  size: number; // number of elements in data array
  total?: number | null; // total count from header
  range?: HeaderRange; // range of items
}

export function notLast(r: ManyResult<any>): boolean {
  return r.size > 0 && (r.range?.ended ?? 0) + 1 !== r.total;
}

export interface HeaderRange {
  open: number;
  ended?: number | null;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export class RangeUtils {
  static fromHeader(range: string): HeaderRange {
    const r = range.split('-');
    return { open: +r[0], ended: r[1] ? +r[1] : null };
  }

  static toHeader({ ended, open }: HeaderRange): string {
    return `${open}-${ended ? ended : ''}`;
  }
}
