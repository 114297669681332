import { Logger } from '@abstractions';

export abstract class AbstractLogger implements Logger {
  private _enabled!: boolean;
  protected nextLogger!: AbstractLogger;

  protected abstract onError(logMessage: string, error?: Error): void;

  protected onLog(logMessage: string): void {}
  protected onWarn(logMessage: string): void {}
  protected onInfo(logMessage: string): void {}

  private logAction(currentLoggerAction: () => void, nextLoggerAction: () => void): void {
    if (this.enabled) {
      currentLoggerAction();
    }

    if (this.nextLogger) {
      nextLoggerAction();
    }
  }

  setNextLogger(logger: AbstractLogger): void {
    this.nextLogger = logger;
  }

  set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  error(logMessage: string, error?: Error): void {
    this.logAction(
      () => this.onError(logMessage, error),
      () => this.nextLogger.error(logMessage, error),
    );
  }

  info(logMessage: string): void {
    this.logAction(
      () => this.onInfo(logMessage),
      () => this.nextLogger.info(logMessage),
    );
  }

  log(logMessage: string): void {
    this.logAction(
      () => this.onLog(logMessage),
      () => this.nextLogger.log(logMessage),
    );
  }

  warn(logMessage: string): void {
    this.logAction(
      () => this.onWarn(logMessage),
      () => this.nextLogger.warn(logMessage),
    );
  }
}
