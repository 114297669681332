import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

import { EnvironmentConfig } from '@abstractions';
import { LocationStrategy } from '@angular/common';
import { NcUrlSerializer } from '../routing/nc-url-serializer';
import { getBaseUrl } from '@utils';
import Keycloak from 'keycloak-js/lib/keycloak';

export function initializeKeycloak(
  keycloak: KeycloakService,
  location: LocationStrategy,
  urlSerializer: NcUrlSerializer,
  envConfig: EnvironmentConfig,
): Function {
  return () => {
    /**
     * vyhodnotí jestli platnost accessTokenu nevyprší během platnosti refreshTokenu, pokud ano, tak vrátí `true`
     */
    function expireSessionBetweenTokensValidity(keycloakAuth: Keycloak) {
      const accExp = keycloakAuth.tokenParsed?.exp;
      const iat = keycloakAuth.tokenParsed?.iat;
      const refExp = keycloakAuth.refreshTokenParsed?.exp;

      if (!accExp || !iat || !refExp) {
        return false;
      }

      return refExp - accExp < accExp - iat;
    }

    return new Promise<void>(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: envConfig.keycloakUrl + '/keycloak',
            realm: 'direct',
            clientId: 'core-fe',
          },
          initOptions: {
            onLoad: 'check-sso', // login-required v pripade cele
            pkceMethod: 'S256',
            silentCheckSsoRedirectUri: getBaseUrl(location) + 'assets/silent-check-sso.html',
            redirectUri: urlSerializer.convertUrl(window.location.href),
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
        });

        const keycloakAuth = keycloak.getKeycloakInstance();
        keycloak.keycloakEvents$.subscribe({
          next: (e) => {
            if (e.type == KeycloakEventType.OnTokenExpired) {
              if (expireSessionBetweenTokensValidity(keycloakAuth)) {
                keycloak.logout(window.location.href);
              } else {
                keycloak.updateToken(30);
              }
            }
            if (e.type == KeycloakEventType.OnAuthRefreshError) {
              // logout v tomto bodě redirectne na stránku s vypršenou expirací tokenu
              keycloak.logout(window.location.href);
            }
          },
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
