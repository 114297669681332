import { AbstractLogger } from '../abstract/abstract-logger';
import { Logger } from '@abstractions';

export class ConsoleLogger extends AbstractLogger implements Logger {
  constructor() {
    super();
    this.enabled = true;
  }

  protected onError(logMessage: string, error: Error): void {
    console.error(logMessage, error);
  }

  protected override onInfo(logMessage: string): void {
    console.info(logMessage);
  }

  protected override onLog(logMessage: string): void {
    console.log(logMessage);
  }

  protected override onWarn(logMessage: string): void {
    console.warn(logMessage);
  }
}
